import React from "react";
import { BallTriangle } from "react-loader-spinner";

const Loader = () => {
  return (
    <BallTriangle
  height={100}
  width={100}
  radius={5}
  color="#262b40"
  ariaLabel="ball-triangle-loading"
  wrapperStyle={{ display: "block" }}
  wrapperClass="text-center mt-5"
  visible={true}
  />
    // <TailSpin
    //   height="80"
    //   width="80"
    //   color="#262b40"
    //   ariaLabel="tail-spin-loading"
    //   radius="1"
    //   wrapperStyle={{ display: "block" }}
    //   wrapperClass="text-center mt-5"
    //   visible={true}
    // />
  );
};

export default Loader;
