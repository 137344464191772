import React, { useEffect, useState } from "react";
import { format } from "timeago.js";
import Sidebar from "../Sidebar/Sidebar";
import { useSelector } from "react-redux";
import { API_URL } from "../../constants/urls";
import { useLocation, NavLink } from "react-router-dom";
import rain from "../../Static/Img/Devices&channels/speedometer.gif";
import { download } from "./DeviceFunctions";
import DatePicker from "react-datepicker";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import Dropdown from "react-bootstrap/Dropdown";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  // responsive: true,
  maintainAspectRatio:false,
  tension:0.3,
  plugins: {
    legend: {
      position: "top",
    },
  },
};

const labels = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10",
                "11","12","13","14","15","16","17","18","19","20",
                "21","22","23","24","25","26","27","28","29","30",
                "31","32","33","34","35","36","37","38","39","40",
                "41","42","44","44","45","46","47","48","49","50"];

const Devices = () => {
  const { user } = useSelector((state) => ({ ...state.auth }));
  const location = useLocation();
  const pastDate = new Date();
  pastDate.setDate(pastDate.getDate() - 365);
  const nDate = new Date();
  nDate.setDate(nDate.getDate() - 30);
  let [channels, setChannels] = useState([]);
  let [refreshData, setRefreshData] = useState(false);
  let [file, setFile] = useState([]);
  const [startDate, setStartDate] = useState(nDate);
  const [endDate, setEndDate] = useState(new Date());

  let TableData = [];

  useEffect(() => {
    const url = `${API_URL}/get-channels/${location.state.deviceId}`;

    const fetchData = async () => {
      try {
        const response = await fetch(url);
        const json = await response.json();
        await setChannels(json.data);
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchData();
  }, [refreshData,location.state.deviceId]);

  useEffect(() => {
    const url = `${API_URL}/get-device-data/${location.state.deviceId}?startDate=${startDate}&endDate=${endDate}`;

    const fetchData = async () => {
      try {
        const response = await fetch(url);

        const json = await response.json();

        var y = [...json.data].reverse();
        await setFile(y);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchData();
  }, [refreshData,startDate, endDate, location.state.deviceId]);

  const deleteDeviceData = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then( async (result) => {
      if (result.isConfirmed) {
       try {
         const response = await fetch(`${API_URL}/delete-device-data/${location.state.deviceId }`);
         if (response.status === 200) {
           Swal.fire(
             'Deleted!',
             'Data has been deleted.',
             'success'
           )
           setRefreshData(true)
         }
       } catch (error) {
         console.log("error", error);
       }
       
      }
    })
  }

    for (let i = 0; i < file.length; i++) {
      const bodys = Object.values(file[i] ?? {});
      TableData.push(bodys);
    }
  

  let arr = [];
  let colors = [
    "rgb(255, 20, 147)","rgb(255, 165, 0)","rgb(106, 90, 205)","rgb(50, 205, 50)","rgb(165, 42, 42)","rgb(255,0,255)",
  ];
 async function chartData (channels) {
    try {
      for (let i = 0; i < channels.length; i++) {

        let firstCells = await file.slice(0, 50)
       
        let cellValues = [];
        
        await firstCells.forEach((element) => {
          let keyVal = Object.keys(element)[i+3]
          cellValues.push(element[keyVal])
        });

        cellValues.reverse()

        let obj = await {
          label: channels[i].channelDisplayName
            ? channels[i].channelDisplayName
            : channels[i].channelName,
          data: await cellValues,
          borderColor: colors[i],
          backgroundColor: colors[i],
        };
        if (arr.length <= channels.length) {
          await arr.push(obj);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const data = {
    labels,
    datasets: arr,
  };
  chartData(channels)
  return (
    <>
      <Sidebar
        element={
          <>
            {" "}
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="row  mt-5 mb-4">
                    <div className="col-sm-6">
                      <h3 className="blue-text font_weight">Device Channels</h3>
                    </div>
                    <div className="col-sm-6">
                      <div className="ms-auto" style={{ width: "fit-content" , display:"flex" }}>
                        <Dropdown style={{ display: "inline-block" }}>
                          <Dropdown.Toggle
                            className="btn-2"
                        
                            id="dropdown-basic"
                          >
                            Export
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={(e) => {
                                download("tbl");
                              }}
                            >
                              Export CSV
                            </Dropdown.Item>
                            {/* <Dropdown.Item href="#/action-2">Export PDF</Dropdown.Item> */}
                          </Dropdown.Menu>
                        </Dropdown>
                        {user?.role === "Dealer" || user?.role === "user" ? (
                          ""
                        ) : (
                          <NavLink
                            to="/device-settings/"
                            state={{ deviceId: location.state.deviceId }}
                            className="ms-2"
                          >
                            {" "}
                            <button
                              type="button"
                              style={{ display: "inline-block" }}
                              className="btn-2 submit-btn"
                            >
                              Settings
                            </button>{" "}
                          </NavLink>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {channels &&
                  channels.length > 0 &&
                  channels.map((channel, index) => {
                    return (
                      <>
                        <div className="mb-4 col-12 col-md-4 col-lg-3">
                          <div
                            className="card p-2"
                        
                          >
                            <div className="row g-0">
                              <div className="col-md-4 m-auto">
                              
                                <img
                                  src={rain}
                                  style={{
                                    width: "70%",
                                    display: "block",
                                    margin: "auto",
                                  }}
                                  className="img-fluid rounded-start mt-2"
                                  alt="..."
                                />
                              </div>
                              <div className="col-md-8">
                                <div className="card-body">
                                  <h5 className="card-text">
                                    {channel.channelDisplayName
                                      ? channel.channelDisplayName
                                      : channel.channelName}
                                    : {channel.channelData}
                                  </h5>
                                  <p className="card-text">
                                    <small className="text-muted">
                                      Last Updated: {format(channel.updatedAt)}
                                    </small>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>

              <div className="row">
                <div className="col-12 mb-5">
                  <div
                    className="py-4"
                    style={{
                      borderRadius: "10px",
                      boxShadow: "rgb(0 0 0 / 40%) 0px 0px 8px 0px",
                      backgroundColor: "white",
                    }}
                  >
                    <div className="col-md-12 col-12 mx-auto" style={{minHeight: "400px"}}>
                      <Line options={options} data={data} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 mb-5">
                  <div
                    className="p-4"
                    style={{
                      borderRadius: "10px",
                      boxShadow: "rgb(0 0 0 / 40%) 0px 0px 8px 0px",
                      backgroundColor: "white",
                    }}
                  >
                    <div className="col-md-12 mx-auto">
                      <h3 className="font_weight">Device Logs</h3>
                      <div className="col-md-12">
                        <div className="row mb-3">
                          <div className="col-md-4">
                            <p>Start Date</p>
                            <DatePicker
                              className="input_3"
                              showIcon
                              selected={startDate}
                              minDate={pastDate}
                              maxDate={new Date()}
                              onChange={(date) => setStartDate(date)}
                            />
                          </div>
                          <div className="col-md-8">
                            <p>End Date</p>
                            <DatePicker
                              className="input_3"
                              showIcon
                              selected={endDate}
                              minDate={pastDate}
                              maxDate={new Date()}
                              onChange={(date) => setEndDate(date)}
                            />
                          </div>
                        </div>
                      </div>
                      <div style={{ overflowY: "auto",maxHeight: "500px",top: "0px",position: "sticky"}}>
                             
                      <table
                        class="table table-striped mb-0 text-center"
                        id="tbl"
                       style={{borderCollapse:"collapse",width:"100%"}}
                      >
                        <thead style={{top: "0px",position: "sticky", background:"#fff"}}>
                          <tr>
                            <th>Event Id</th>
                            <th>Device Id</th>
                            <th>Date</th>
                            {channels &&
                              channels.length > 0 &&
                              channels.map((channel) => {
                                return (
                                  <>
                                    <th>
                                      {channel.channelDisplayName
                                        ? channel.channelDisplayName
                                        : channel.channelName}
                                    </th>
                                  </>
                                );
                              })}
                          </tr>
                        </thead>
                        <tbody>
                          {TableData.map((numList, i) => {
                            return (
                              <tr key={i}>
                                {numList.map((num, j) => (
                                  <td key={j}>{num}</td>
                                ))}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      </div>
                      <button
                      onClick={deleteDeviceData}
                        type="button"
                        style={{ display: "block" }}
                        className=" ms-auto mt-3 btn btn-danger submit-btn py-2 px-4"
                      >
                        Clear Data
                      </button>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
      />
    </>
  );
};

export default Devices;
