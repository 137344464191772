import React, { useEffect , useState} from "react";
import Sidebar from "../Sidebar/Sidebar";
import {API_URL} from '../../constants/urls';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import Toggle from 'react-styled-toggle';
const DeviceSttings = () => {

 const location = useLocation();

    let [refreshSettings, setRefreshSettings] = useState(false)
    let [deviceInfo, setDeviceInfo] = useState({})
    let [channels, setChannels] = useState([])
    let [channelDisplayName, setChannelDisplayName] = useState(null)
    let [prefix, setPrefix] = useState(null)
    let [postfix, setPostfix] = useState(null)
    let [min, setMin] = useState(null)
    let [max, setMax] = useState(null)
    let [minTemp, setMinTemp] = useState(deviceInfo?.minTemp)
    let [maxTemp, setMaxTemp] = useState(deviceInfo?.maxTemp)
    let [dataInterval, setDataInterval] = useState(deviceInfo?.dataInterval)
    let [lat, setLat] = useState(deviceInfo?.lat)
    let [lng, setLng] = useState(deviceInfo?.lng)
    let [phoneNo, setPhoneNo] = useState(deviceInfo?.phoneNo)
    let [name, setName] = useState(deviceInfo?.name)
    let [loc, setLoc] = useState(deviceInfo?.loc)
    let [api_key, setApiKey] = useState(deviceInfo?.api_key)
    let [api_url, setApiUrl] = useState(deviceInfo?.api_url)
    let [fieldName, setFieldName] = useState(deviceInfo?.fieldName)
    let [forwardData, setForwardData] = useState(false)


    useEffect(() => {
      const url = `${API_URL}/get-channels/${location.state.deviceId}`;
  
      const fetchData = async () => {
        try {
          const response = await fetch(url);
          const json = await response.json();
          
          await setChannels(json.data);
        } catch (error) {
          console.log("error", error);
        }
      };
      fetchData();
    }, [refreshSettings]);

    useEffect(() => {
      const url = `${API_URL}/get-device/${location.state.deviceId}`;
  
      const fetchData = async () => {
        try {
          const response = await fetch(url);
          const json = await response.json();
          await setDeviceInfo(json.device);
          await setForwardData(json.device.forwardData)
        } catch (error) {
          console.log("error", error);
        }
      };
      fetchData();
    }, [refreshSettings]);
    
    const updateDevice = async (e) => {
     let deviceInfo = {location:loc, lat, lng, phoneNo, name, minTemp, maxTemp,api_key, api_url, forwardData, fieldName, dataInterval}
      try {
        let result = await fetch(`${API_URL}/update-device/${location.state.deviceId}`, {
          method: "POST",
          body: JSON.stringify(deviceInfo),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
          }
        });
        if (result.status=== 200) {
          successMessage("Device Updated Successfully!")
        }
        result = await result.json();


      } catch (err) {
        console.log(err)
    }
    }

    const updateChannel = async (e) =>{

        let channelInfo = {channelDisplayName, prefix, postfix, min, max };
        try {
            let result = await fetch(`${API_URL}/update-channel/${e}`, {
              method: "POST",
              body: JSON.stringify(channelInfo),
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
              }
            });
            if (result.status=== 200) {
              successMessage("Channel Updated Successfully!")
            }
            result = await result.json();
         
        } catch (err) {
            console.log(err)
        }

      }

      const successMessage = (title) => {
        Swal.fire({
          title: title,
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
            setRefreshSettings(true)
          }
        })
      }

  return (
    <>
      <Sidebar
        element={
          <>
            {" "}
            <div className="container">
            <div className="row  mt-5">
                <div className="col-md-12">
                  <div className="row mt-5 mb-4">
                <div className="col-6">
                    <h3 className="blue-text font_weight">Device Settings</h3>
                </div>
                <div className="col-6">
               
               </div> 
              </div>
            </div>    
          </div>
            <div className="row">
            <div className="col-12 mb-5" >
        <div className="p-4" style={{ borderRadius:"10px", boxShadow: "rgb(0 0 0 / 40%) 0px 0px 8px 0px", backgroundColor:"white"}}>
            <div className="col-md-12">
                <h4 >Edit Device</h4>
                <div className="">
                <div className="mt-3 mb-4">
                <label for="floatingName">Name</label>
                <input type="text" className="input_3"  onChange={(e)=> setName(e.target.value)} defaultValue={deviceInfo?.name} id="floatingName" placeholder="Enter your Name"/>
            </div>
            <div className=" mb-4">
                <label for="floatingEmail">Location</label>
                <input type="email" className="input_3" onChange={(e)=> setLoc(e.target.value)} defaultValue={deviceInfo?.location} id="floatingEmail" placeholder="Enter your Location"/>
            </div>
            <div className=" mb-4">
                <label for="floatingPhone">Lat</label>
                <input type="number"className="input_3" onChange={(e)=> setLat(e.target.value)} defaultValue={deviceInfo?.lat} id="floatingPhone" placeholder="Enter your Lat"/>
            </div>
            <div className=" mb-4">
                <label for="floatingAddress">Lng</label>
                <input type="text" className="input_3" onChange={(e)=> setLng(e.target.value)} defaultValue={deviceInfo?.lng} id="floatingAddress" placeholder="Enter your Lng"/>
            </div>
            <div className="row mb-4">
            <div className="col-md-3 col-6 mb-4">
                <label for="floatingAddress">API Key</label>
                <input type="text" className="input_3" onChange={(e)=> setApiKey(e.target.value)} defaultValue={deviceInfo?.api_key} id="floatingKey" placeholder="Enter your API Key"/>
            </div>
            <div className="col-md-3 col-6 mb-4">
                <label for="floatingAddress">API URL</label>
                <input type="text" className="input_3" onChange={(e)=> setApiUrl(e.target.value)} defaultValue={deviceInfo?.api_url} id="floatingUrl" placeholder="Enter your API URL"/>
            </div>
            <div className="col-md-3 col-6 mb-4">
                <label for="floatingAddress">Field Name</label>
                <input type="text" className="input_3" onChange={(e)=> setFieldName(e.target.value)} defaultValue={deviceInfo?.fieldName} id="floatingFieldName" placeholder="Enter your Field Name"/>
            </div>
            <div className="col-md-3 col-6 mb-4 text-center">
                <label className="" for="floatingCheck">Forword Data</label>
              <Toggle backgroundColorChecked="#262b40" onChange={() => setForwardData(!forwardData)}   checked={forwardData} />
            </div>
            </div>
            <div className="row mb-4">

            <div className="col-6 mb-4">
                <label for="floatingAddress">Current Phone No.</label>
                <input type="text" className="input_3" onChange={(e)=> setPhoneNo(e.target.value)} defaultValue={deviceInfo?.phoneNo?.current} id="floatingPhone" placeholder="Enter your Current Phone No"/>
            </div>
            <div className="col-6 mb-4">
                <label for="floatingAddress">Previous Phone No.</label>
                <input type="text" className="input_3" defaultValue={deviceInfo?.phoneNo?.previous}  placeholder="Enter your Previous Phone No" disabled/>
            </div>
            </div>
            <div className="row mb-4">
            <div className="col-6 mb-4">
                <label for="floatingAddress">Current Minimum Temp.</label>
                <input type="text" className="input_3" onChange={(e)=> setMinTemp(e.target.value)} defaultValue={deviceInfo?.minTemp?.current} id="floatingPhone" placeholder="Enter your Current Minimum Temp"/>
            </div>
            <div className="col-6 mb-4">
                <label for="floatingAddress">Previous Minimum Temp.</label>
                <input type="text" className="input_3" defaultValue={deviceInfo?.minTemp?.previous}  placeholder="Enter your Previous Minimum Temp" disabled/>
            </div>
            </div>
            <div className="row mb-4">
            <div className="col-6 mb-4">
                <label for="floatingAddress">Current Maximum Temp.</label>
                <input type="text" className="input_3" onChange={(e)=> setMaxTemp(e.target.value)} defaultValue={deviceInfo?.maxTemp?.current} id="floatingPhone" placeholder="Enter your Current Maximum Temp"/>
            </div>
            <div className="col-6 mb-4">
                <label for="floatingAddress">Previous Maximum Temp.</label>
                <input type="text" className="input_3"  defaultValue={deviceInfo?.maxTemp?.previous}  placeholder="Enter your Previous Maximum Temp" disabled/>
            </div>
            </div>
            <div className="row mb-4">
            <div className="col-6 mb-4">
                <label for="floatingAddress">Current Data Interval</label>
                <input type="text" className="input_3" onChange={(e)=> setDataInterval(e.target.value)} defaultValue={deviceInfo?.dataInterval?.current} id="floatingPhone" placeholder="Enter your Previous Maximum Temp"/>
            </div>
            <div className="col-6 mb-4">
                <label for="floatingAddress">Previous Device Interval</label>
                <input type="text" className="input_3" defaultValue={deviceInfo?.dataInterval?.previous}  placeholder="Enter your Previous Device Interval" disabled/>
            </div>
            </div>
            <div className="">
            <button type="button" style={{display:"block"}} onClick={updateDevice}
                    className="btn ms-auto blue-background white submit-btn  px-4">
                    Update
            </button>
            </div>
                </div>
             </div>
            </div>
        </div>
        </div>
              <div className="row">
                <div className="col-12 mb-5" >
                  <div className="p-4"style={{ borderRadius:"10px", boxShadow: "rgb(0 0 0 / 40%) 0px 0px 8px 0px", backgroundColor:"white"}}>
                    <div className="col-md-12">
                        <h4>Edit Channel</h4>
                    <table class="table text-center">
                    <thead>
                        <tr>
                        <th scope="col">Channel</th>
                        <th scope="col">Name</th>
                        <th scope="col">Prefix</th>
                        <th scope="col">Postfix</th>
                        <th scope="col">Min Threshold</th>
                        <th scope="col">Max Threshold</th>
                        <th scope="col">Action</th>
                        </tr>
                    </thead>
                        <tbody>
                        {channels &&
                            channels.length > 0 &&
                            channels.map((channel, index) => {

                               

                                return (
                                <>
                                <tr>
                                
                                    <td>{channel.channelName}</td>
                                    <td> <input type="text" class="input_3" placeholder="Name" aria-label="displayName" onChange={(e)=> setChannelDisplayName(e.target.value)} defaultValue={channel.channelDisplayName} /></td>
                                    <td><input type="text" class="input_3" placeholder="Prefix" aria-label="prefix" onChange={(e)=> setPrefix(e.target.value)}  defaultValue={channel.prefix} /></td>
                                    <td><input type="text" class="input_3" placeholder="Postfix"aria-label="postfix" onChange={(e)=> setPostfix(e.target.value)} defaultValue={channel.postfix} /></td>
                                    <td><input type="number" class="input_3" placeholder="Min Threshold" aria-label="min" onChange={(e)=> setMin(e.target.value)} defaultValue={channel.min} /></td>
                                    <td><input type="number" class="input_3" placeholder="Max Threshold" aria-label="max" onChange={(e)=> setMax(e.target.value)} defaultValue={channel.max} /></td>
        
                                    <td><button
                                    type="button"
                                    style={{display:"block"}}
                                    onClick={(e) => {updateChannel(channel._id)}}
                                    className="btn ms-auto blue-background white submit-btn  px-4">
                                    Update</button>
                                    </td>
                                </tr>
                                </>
                                );
                            })}
                        </tbody>
                        </table>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
      />
    </>
  );
};

export default DeviceSttings;
