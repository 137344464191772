import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeviceImg from "../../Static/Img/Dashboard/metronome.gif";
import { getDevices } from "../../slices/devicesList";
import Loader from "../Loader/Loader";
import { API_URL } from "../../constants/urls";
const DevicesList = () => {
  const { user } = useSelector((state) => ({ ...state.auth }));
  // const { loading } = useSelector((state) => state.deviceDetails);
  let [loading, setLoading] = useState(true)
  const [devices, setDevices] = useState([])
  const [message, setMessage] = useState('')
  
  const userIds = user?.id

  useEffect(() => {
    let userId = Location.state ? Location.state.userId : user?.id;

    const url = `${API_URL}/get-devices/${userId}`;

    const fetchData = async () => {
      try {
        const response = await fetch(url);
        const json = await response.json();

        if (!response.ok) {
          setLoading(false)
          setMessage(json.message)
        } else {
          setLoading(false)
          await setDevices(json.data);
        }

      } catch (error) {
        console.log("error", error);
      }
    };

    fetchData();
  }, [Location.state, userIds]);
  

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className="row">
        {devices &&
          devices.length > 0 &&
          devices.map((device, index) => {
            return (
              <>
                <div key={index} className="mb-4  col-12 col-sm-6 col-md-4 col-lg-3">
                  <div
                    className="card p-2"
                 
                  >
                    <div className="row g-0">
                      <div className="col-md-4">
                        <img
                          src={DeviceImg}
                          style={{
                            width: "70%",
                            display: "block",
                            margin: "auto",
                          }}
                          className="img-fluid rounded-start mt-2"
                          alt="..."
                        />
                      </div>
                      <div className="col-md-8">
                        <div className="card-body">
                          <h5 className="card-text" style={{"fontSize" : "15px"}}>{device.deviceId}</h5>
                          <p className="card-text">
                            <small className="text-muted">
                              Channels: {device.parameter}
                            </small>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
      </div>
      {!devices || devices.length === 0 ? (
        <div className="text-center mt-5">
          <h1>{message}</h1>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default DevicesList;
