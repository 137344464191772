
const download = (table_id, separator = ',') => {
    
    try {
      var rows = document.querySelectorAll('table#' + table_id + ' tr');
      var csv = [];
      for (var i = 0; i < rows.length; i++) {
          var row = [], cols = rows[i].querySelectorAll('td, th');
          for (var j = 0; j < cols.length; j++) {
              var data = cols[j].innerText.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')
              data = data.replace(/"/g, '""');
          
              row.push('"' + data + '"');
          }
          csv.push(row.join(separator));
      }
      var csv_string = csv.join('\n');
      var filename = 'Data_Log_' + new Date().toLocaleDateString() + '.csv';
      var link = document.createElement('a');
      link.style.display = 'none';
      link.setAttribute('target', '_blank');
      link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv_string));
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
   catch (err) {
    console.log(err)
   }
  }


  export { download }