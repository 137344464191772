import { useEffect, useState, memo } from "react";
import { API_URL } from "../../constants/urls";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import Swal from "sweetalert2";
import Loader from "../Loader/Loader";

const DeviceList = () => {
  let [devices, setDevices] = useState([]);
  let [refreshDevice, setRefreshDevice] = useState(false);
  let [message, setMessage] = useState("");
  let [loading, setLoading] = useState(true)
  let location = useLocation();
  const navigate = useNavigate();

  const { user } = useSelector((state) => ({ ...state.auth }));

  const userIds = user?.id;

  useEffect(() => {

    let userId = location.state ? location.state.userId : userIds;
    
    const url = `${API_URL}/get-devices/${userId}`;

    const fetchData = async () => {
      try {
        const response = await fetch(url);
        const json = await response.json();

        if (!response.ok) {
          setLoading(false)
          setMessage(json.message)
        } else {
          setLoading(false)
          await setDevices(json.data);
        }
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchData();
  }, [refreshDevice, location.state, userIds]);

  const deleteDevice = (deviceId, deviceId2) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await fetch(
            `${API_URL}/delete-device/${deviceId}/${deviceId2}`
          );
          if (response.status === 200) {
            Swal.fire("Deleted!", "Device has been deleted.", "success");
            setRefreshDevice(true);
          }
        } catch (error) {
          console.log("error", error);
        }
      }
    });
  };

  const deviceDetails = (e, name) => {
    navigate("/device-channels", { state: { deviceId: e, name:name } });
  };

  if (loading) {
    return (
        <Loader/>
    )
  }
  return (
    <div className="row">
      <div className="col-md-12">
      <table className="table no-wrap table-hover mb-0 text-center">
        <thead>
          <tr>
            <th
              scope="col"
              class="border-0 text-uppercase font-medium blue-text pl-4"
            >
              S.No
            </th>
            <th
              scope="col"
              class="border-0 text-uppercase font-medium blue-text"
            >
              Device Id
            </th>
            <th
              scope="col"
              class="border-0 text-uppercase font-medium blue-text"
            >
              Name
            </th>
            <th
              scope="col"
              class="border-0 text-uppercase font-medium blue-text"
            >
              Location
            </th>
            <th
              scope="col"
              class="border-0 text-uppercase font-medium blue-text"
            >
              Device Channels
            </th>
            <th
              scope="col"
              class="border-0 text-uppercase font-medium blue-text"
            >
              Created On
            </th>

            {user?.role === "admin" ? (
              <th
                scope="col"
                class="border-0 text-uppercase font-medium blue-text"
              >
                Action
              </th>
            ) : (
              ""
            )}
          </tr>
        </thead>
        <tbody>
          {devices &&
            devices.length > 0 &&
            devices.map((device, index) => {
              return (
                <>
                  <tr key={index} style={{ cursor: "pointer" }}>
                    <td
                      class="pl-4 grey-text"
                      onClick={() => {
                        deviceDetails(device.deviceId);
                      }}
                    >
                      {index + 1}
                    </td>
                    <td
                      onClick={() => {
                        deviceDetails(device.deviceId);
                      }}
                    >
                      <span class="font-medium mb-0 grey-text">
                        {device.deviceId}
                      </span>
                    </td>
                    <td
                      onClick={() => {
                        deviceDetails(device.deviceId);
                      }}
                    >
                      <span class="font-medium mb-0 grey-text">
                        {device.name ? device.name : "-"}
                      </span>
                    </td>
                    <td
                      onClick={() => {
                        deviceDetails(device.deviceId);
                      }}
                    >
                      <span class="font-medium mb-0 grey-text">
                        {device.location ? device.location : "-"}
                      </span>
                    </td>
                    <td
                      onClick={() => {
                        deviceDetails(device.deviceId);
                      }}
                    >
                      <span class="font-medium grey-text">
                        {device.parameter}
                      </span>
                    </td>
                    <td
                      onClick={() => {
                        deviceDetails(device.deviceId);
                      }}
                    >
                      <span class="font-medium grey-text">
                        {moment(new Date(device.createdAt)).format('DD-MM-YYYY hh:mm A')}
                       
                      </span>
                    </td>

                    {user?.role === "admin" ? (
                      <td>
                        <button
                          type="button"
                          class="btn btn-danger"
                          onClick={() => {
                            deleteDevice(device._id, device.deviceId);
                          }}
                        >
                          <i class="fa-solid fa-trash"></i>
                        </button>
                      </td>
                    ) : (
                      ""
                    )}
                  </tr>
                </>
              );
            })}
        </tbody>
      </table>
      {!devices || devices.length === 0 ? (
        <div className="text-center mt-5">
          <h1>{message}</h1>
        </div>
      ) : (
        ""
      )}
    </div>    </div>
  );
};

export default memo(DeviceList);
