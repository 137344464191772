import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Sidebar from "../../Sidebar/Sidebar";
import { API_URL } from "../../../constants/urls";
import Swal from 'sweetalert2';
import Toggle from "react-styled-toggle";

const EditUser = () => {
  const location = useLocation();
  let navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({});
  const [name, setName] = useState(userInfo?.name);
  const [email, setEmail] = useState(userInfo?.email);
//   const [password, setPassword] = useState("");
//   const [confirmPassword, setConfirmPassword] = useState("");
  const [phone, setPhone] = useState(userInfo?.phone_no);
  const [address, setAddress] = useState(userInfo?.address);
  const [suspend, setSuspend] = useState(false);
  const [message, setMessage] = useState("");
  const [message2, setMessage2] = useState(userInfo?.message);
  const [errMsg, setErrMsg] = useState(false)



  
  useEffect(() => {
    const url = `${API_URL}/get-single-user/${location.state.user}`;

    const fetchData = async () => {
      try {
        const response = await fetch(url);
        const json = await response.json();
        await setUserInfo(json.user);
        await setName(json.user.name);
        await setPhone(json.user.phone_no);
        await setEmail(json.user.email);
        await setMessage2(json.user.message);
        await setAddress(json.user.address);
        await setSuspend(json.user.isSuspend)
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchData();
  }, []);


  const updateUser = async () =>{
    let userInformation = { 
        name:name, 
        email:email, 
        // password, 
        // confirmPassword, 
        address:address, 
        // role:"user", 
        // level: 4, 
        userId: location.state.user,
        message:message2,
        phone_no:phone, 
        isSuspend: suspend
       
      };

    let result = await fetch(`${API_URL}/edit-single-user`, {
      method: "POST",
      body: JSON.stringify(userInformation),
      headers: {
        "Content-Type": "application/json",
        mode: "cors"
      }
    });
    if (result.status === 400) {
      setErrMsg(true);
    }
    if (result.status=== 200) {
        editUser()
    }
    
    result = await result.json();
    setMessage(result.message);
    
  }

  const editUser = () => {
    Swal.fire({
      title: `${location.state.type} Edited Successfully!`,
      icon: 'success',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK'
    }).then((result) => {
      if (result.isConfirmed) {
        if (location.state.type === "User") {
            navigate("/users-list")
        } else if (location.state.type === "Dealer") {
            navigate("/dealers-list")
        }
        
      }
    })
  }

  return (
    <>
    <Sidebar element={

                <div className="container pb-5">
                <div className="row" style={{ paddingTop: "50px;" }}>
                <div className="col-md-12 mt-5 mb-4">
                    <div>
                    <h3 className="blue-text font_weight">Edit {location.state.type}</h3>
                    </div>
                </div>

                </div>
                <div className="row">
                    <div className="col-md-8 mx-auto">
        <form className="p-3 p-md-5 form_add"  >
        { errMsg === true ? <div class="alert alert-danger text-center" role="alert"> {message} </div> : ""}
            <div className=" mb-4">
                <label for="floatingName">Name</label>
                <input type="text" className="input_3" name="name" onChange={(e)=> setName(e.target.value)} defaultValue={userInfo?.name}  id="floatingName" placeholder="Name"/>
            </div>
            <div className=" mb-4">
                <label for="floatingEmail">Email</label>
                <input type="email" className="input_3" name="email" onChange={(e)=> setEmail(e.target.value)} defaultValue={userInfo?.email}  id="floatingEmail" placeholder="name@example.com"/>
            </div>
            <div className=" mb-4">
                <label for="floatingPhone">Phone No.</label>
                <input type="number" className="input_3" name="phone" onChange={(e)=> setPhone(e.target.value)} defaultValue={userInfo?.phone_no}  id="floatingPhone" placeholder="9999999999"/>
            </div>
            <div className=" mb-4">
                <label for="floatingAddress">Address</label>
                <input type="text" className="input_3" name="address" onChange={(e)=> setAddress(e.target.value)} defaultValue={userInfo?.address}  id="floatingAddress" placeholder="Example address"/>
            </div>
            <div className=" mb-4">
                <label for="floatingPassword">Suspension Message</label>
                <input type="text" className="input_3" name="message2" onChange={(e)=> setMessage2(e.target.value)} defaultValue={userInfo?.message}  id="floatingMessage" placeholder="Message"/>
            </div>
            <div className=" mb-4" style={{width:"fit-content"}}>
            <label className="switch" for="floatingCheck">Suspend</label>
              <Toggle backgroundColorChecked="#262b40" onChange={() => setSuspend(!suspend)} checked={suspend} />
            </div>
            {/* <div className="">
                <label for="floatingConfPassword">Confirm Password</label>
                <input type="password" className="form-control" onChange={(e)=> setConfirmPassword(e.target.value)} id="floatingConfPassword" placeholder="Confirm password"/>
            </div> */}
            <div className="">
              <button
                type="button"
                style={{display:"block"}}
                className="btn ms-auto btn-2 submit-btn "
                onClick={updateUser}
              >
                Update
              </button>
            </div>
            </form>
          </div>
               
         </div>
       </div>
                
   }/>
    </>
  );
};

export default EditUser;
