import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/auth";
import userReducer from "./slices/user";
import messageReducer from "./slices/message";
import productsReducer from "./slices/products";
import  deviceDetailReducer  from "./slices/devicesList";


const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    message:messageReducer,
    productList: productsReducer,
    deviceDetails: deviceDetailReducer
  },
  devTools: true
});

export default store;
