import React from "react";
import { useSelector } from "react-redux";
import Sidebar from "../Sidebar/Sidebar";
import { NavLink, useLocation } from "react-router-dom";
import DevicesList from './DevicesList.jsx';
const Dashboard = () => {
  let Location = useLocation();
  const { user } = useSelector((state) => ({ ...state.auth }));

  return (
    <>
      <Sidebar
        element={
          <>
            {" "}
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="row  my-2">
                    <div className="mt-5 mb-4">
                      <h3 className="blue-text font_weight">Welcome {user?.name}</h3>
                    </div>
                    {user?.role === "admin" &&
                    user?.level <= 2 &&
                    Location.state ? (
                      <div className="col-12 mt-5">
                        <NavLink
                          to="/add-device"
                          state={{ userId: Location.state.userId }}
                        >
                          <button
                            type="button"
                            style={{ display: "block" }}
                            className="btn ms-auto blue-background white submit-btn py-2 px-4"
                          >
                            Add Device
                          </button>
                        </NavLink>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
                  <DevicesList />
            </div>
          </>
        }
      />
    </>
  );
};

export default Dashboard;
