import React from "react";


const SignPage = (props) => {
    return(
        <>
            <div className="col-lg-6 col-sm-12 left_side">
          <div className="left_sec">
          <h1 className="font_weight">{props.Head}</h1>
          <p style={{textAlign: "justify"}}>
          {props.Pera}
          </p>

          <h5>If You Any Query</h5>
          <a href="mailto:anoopsingh0096@gmail.com"><svg width="20px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#fff" d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/></svg> anoopsingh0096@gmail.com</a>
        </div>

        <div className="main-container">
		<ul className="circles-container">
			<li className="circle"></li>
			<li className="circle"></li>
			<li className="circle"></li>
			<li className="circle"></li>
			<li className="circle"></li>
			<li className="circle"></li>
			<li className="circle"></li>
			<li className="circle"></li>
			<li className="circle"></li>
			<li className="circle"></li>
		</ul>
	</div>
        </div>
        </>
    )
}

export default SignPage;
