import React, { useState, useEffect } from "react";
import { login } from "../../../slices/auth";
import '../Login.css';
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, NavLink } from "react-router-dom";
import { clearMessage } from "../../../slices/message";
import SignPage from "../PropsPage/SignPage";

const Login = () => {
  let navigate = useNavigate();
  const initialValues = {
    email: "",
    password: ""
  };

  const [formValue, setFormValue] = useState(initialValues);
  const [errMsg, setErrMsg] = useState(false);
  
  const { isLoggedIn } = useSelector(state => state.auth);
  const { message } = useSelector(state => state.message);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const handlePassword = event => {
    let password = event.target.value;
    setFormValue({ ...formValue, password: password });
  };

  const handleEmail = event => {
    let email = event.target.value;
    
    setFormValue({ ...formValue, email: email });
  };

  const handleLogin = (e) => {

    e.preventDefault()
    const { email, password } = formValue;

    dispatch(login({ email, password }))
      .unwrap()
      .then(() => {
        navigate("/dashboard");
      })
      .catch(err => {
        setErrMsg(true);
        console.log(err)
      });
  };

  if (isLoggedIn) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <>

      <div className="form-top" >
        <div className="intro">
        <div className="row">
        <SignPage Head="Sign in to our platform" Pera=" The IoT portal offers secure login access for users to manage their devices and gather data seamlessly. Through the portal, users can remotely control device settings, monitor statuses, and access valuable data insights with ease. It serves as a centralized hub for optimizing device performance and enhancing user experience in the realm of connected technologies."/>
        
<div className="col-lg-6 col-sm-12 right_side">
<div className="form_login">
<div className="head_top">
<h2 className="font_weight">Sign In</h2></div><br/>
<form className=""  onSubmit={(e) => handleLogin(e)}>
        {errMsg === true ? (
              <div
              className="alert alert-danger py-2 mt-4 text-center"
                role="alert"
              >
               
                {message}
              </div>
            ) : (
              ""
            )}
        <div className="form-outline mb-4">
          
        <label className="form-label" for="form2Example1">Email address</label>
          <input type="email" id="form2Example1" className="input_3" placeholder="&#xf0e0; Enter your Email" value={formValue.email}
              onChange={handleEmail}  />
        </div>

       
        <div className="form-outline mb-4">
        <label className="form-label" for="form2Example2">Password</label>
          <input type="password" id="form2Example2" className="input_3" placeholder="&#xf30d; Enter your Password"  value={formValue.password}
              onChange={handlePassword}/>
        </div>

      
        <div className="row mb-4">
        {/*<div class="col d-flex justify-content-center">
          
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="form2Example31" checked />
              <label class="form-check-label" for="form2Example31"> Remember me </label>
            </div>
          </div>*/}

          <div className="col-md-12">
          <div className="pull-right">
            <NavLink to="/forgot-password" >Forgot password?</NavLink>
            </div>
          </div>
        </div>

    
        <button type="submit" className="btn-1 mb-4 mx-auto " >Sign in</button>

    
       {/* <div className="text-center">
          <br />
          <br />
          <button type="button" className="btn btn-link btn-floating mx-1">
            <i className="fab fa-facebook-f"></i>
          </button>

          <button type="button" className="btn btn-link btn-floating mx-1">
            <i className="fab fa-google"></i>
          </button>

          <button type="button" className="btn btn-link btn-floating mx-1">
            <i className="fab fa-twitter"></i>
          </button>

          <button type="button" className ="btn btn-link btn-floating mx-1">
            <i className="fab fa-github"></i>
          </button>
        </div>*/}
      </form>
      <div className="Cradit">
      <a href="https://dreamcoder.in/" target="_blank"><svg  width="18px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#262b40" d="M352 256c0 22.2-1.2 43.6-3.3 64H163.3c-2.2-20.4-3.3-41.8-3.3-64s1.2-43.6 3.3-64H348.7c2.2 20.4 3.3 41.8 3.3 64zm28.8-64H503.9c5.3 20.5 8.1 41.9 8.1 64s-2.8 43.5-8.1 64H380.8c2.1-20.6 3.2-42 3.2-64s-1.1-43.4-3.2-64zm112.6-32H376.7c-10-63.9-29.8-117.4-55.3-151.6c78.3 20.7 142 77.5 171.9 151.6zm-149.1 0H167.7c6.1-36.4 15.5-68.6 27-94.7c10.5-23.6 22.2-40.7 33.5-51.5C239.4 3.2 248.7 0 256 0s16.6 3.2 27.8 13.8c11.3 10.8 23 27.9 33.5 51.5c11.6 26 20.9 58.2 27 94.7zm-209 0H18.6C48.6 85.9 112.2 29.1 190.6 8.4C165.1 42.6 145.3 96.1 135.3 160zM8.1 192H131.2c-2.1 20.6-3.2 42-3.2 64s1.1 43.4 3.2 64H8.1C2.8 299.5 0 278.1 0 256s2.8-43.5 8.1-64zM194.7 446.6c-11.6-26-20.9-58.2-27-94.6H344.3c-6.1 36.4-15.5 68.6-27 94.6c-10.5 23.6-22.2 40.7-33.5 51.5C272.6 508.8 263.3 512 256 512s-16.6-3.2-27.8-13.8c-11.3-10.8-23-27.9-33.5-51.5zM135.3 352c10 63.9 29.8 117.4 55.3 151.6C112.2 482.9 48.6 426.1 18.6 352H135.3zm358.1 0c-30 74.1-93.6 130.9-171.9 151.6c25.5-34.2 45.2-87.7 55.3-151.6H493.4z"/></svg>  Design by DreamCoder</a>
      </div>
      
</div>
</div>
        </div>
        </div>
     
      </div>

    </>
  );
};

export default Login;
