import React from "react";
import { useSelector } from "react-redux";
import Sidebar from "../Sidebar/Sidebar";
import {Navigate, NavLink, useLocation } from "react-router-dom";
import DeviceList from "./DeviceList"

const Devices = () => {
    let Location = useLocation()

  
    const { user } = useSelector(state => ({ ...state.auth }));

    if (user?.role === "Dealer" && !Location.state ) {
      return <Navigate to="/users-list" />;
     }

     
  return (
    <>
      <Sidebar
        element={
          <>
            {" "}
            <div className="container">
            <div className="row">
                <div className="col-md-12">
                  <div className="row  mt-5 mb-4">
                <div className="col-md-6 mb-4">
                    <h3 className="blue-text font_weight">{Location.state? Location.state.userName+" Devices": user?.name+"'s Devices"}</h3>
                </div>
               {user?.role==='admin'&& user?.level <= 2 && user.id ?  <div className="col-md-6">
                 
                 <NavLink to='/add-device' state={{userId:Location.state ? Location.state.userId : ""}}> 
                 <button type="button" style={{display:"block"}}
                 className=" ms-auto submit-btn btn-2">Add Device
               </button>
               </NavLink> 
                 
                   </div> : "" } 
                </div>
                      <DeviceList/>
                </div>
              
              </div>
              </div>
          
          </>
        }
      />
    </>
  );
};

export default Devices;
