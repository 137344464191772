import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../constants/urls";


let initialState = {
  devices: [],
  loading: false,
  error: null
}

export const getDevices = createAsyncThunk(
  "getDevices",
  async (userId, { rejectWithValue }) => {
    const response = await fetch(`${API_URL}/get-devices/${userId}`, {
      method: "GET",
      headers:{
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials':true,
        'Access-Control-Allow-Methods':'POST, GET'
      }
    });

    try {
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);



export const deleteDeviceData = createAsyncThunk(
  "deleteDeviceData",
  async (deviceId, { rejectWithValue }) => {
    const response = await fetch(`${API_URL}/delete-device-data/${deviceId}`, {
      method: "GET",
      headers:{
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials':true,
        'Access-Control-Allow-Methods':'POST, GET'
      }
    });

    try {
      const result = await response.json();
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deviceDetails = createSlice({
    name:"deviceDetails",
    initialState,
    extraReducers:{
        [getDevices.pending]: (state) => {
          console.log("Pending True")
            state.loading = true
        },
        [getDevices.fulfilled]: (state, action) => {
            state.loading = false;
            state.devices = action.payload.data
        },
        [getDevices.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload
        }
    }
})

export default deviceDetails.reducer;
